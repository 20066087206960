<template>
  <div>
    <div
      v-for="(t, idx) in dateType"
      :key="idx"
      class="datepickerButton"
      :class="{ 'date-active': idx === dateTypeClickedIdx }"
      @click="emitDateTypeClick(idx)"
    >
      {{ $t(`siteInfoDetail.siteInfoChoseDate.${t.title}`) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeSeparator',
  props: {
    dateTypeClickedIdx: {
      type: Number,
      default: 0
    },
    dateType: Array
  },
  methods: {
    emitDateTypeClick (idx) {
      this.$emit('date-type-click', idx)
    }
  }
}
</script>

<style lang="scss" scoped>
.datepickerButton {
  @media screen and (max-width: 575.98px) {
    width: calc(50% - 6px);
  }
}
</style>
